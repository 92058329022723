import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './app/store';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './index.css';
import { HashRouter, Routes, Route, Navigate } from "react-router-dom";
import { NewPage } from './features/newPage';
import { Counter } from './features/counter/Counter';
import { Auth } from './features/auth/auth';
import { NoPage } from './features/nopage';

const container = document.getElementById('root')!;
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <HashRouter>
        <Routes>
          <Route path='/' element={<App/>}>
            <Route index element={<Counter/>}/>
            <Route path='newpage' element={<NewPage/>}/>
            <Route path='counter' element={<Counter/>}/>
            <Route path='Auth' element={<Auth/>}/>
           </Route>
        </Routes>
      </HashRouter>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
